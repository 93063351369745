.Our_Products_Page {
  margin: 5% 0%;
}

.Our_Products_Page .filter-holder {
  height: 900px;
  overflow: hidden;
  overflow-y: auto;
}

.Our_Products_Page .filter-holder::-webkit-scrollbar {
  width: 5px;
}
.Our_Products_Page .filter-holder::-webkit-scrollbar:horizontal {
  height: 8px;
}
/* Track */
.Our_Products_Page .filter-holder::-webkit-scrollbar-track {
  background: #E8E8E8;
}
.Our_Products_Page .filter-holder::-webkit-scrollbar-track:horizontal {
  background: #E8E8E8;
}
/* Handle */
.Our_Products_Page .filter-holder::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 5px;
}
.Our_Products_Page .filter-holder::-webkit-scrollbar-thumb:horizontal{
   background: #c7c7c7;
  border-radius: 5px;
   }

.Our_Products_Page .filter-holder .category_options_holder .options-holder {
  margin: 15px 0px;
  border-bottom: 1px dashed #ccc;
}

.Our_Products_Page .option_text {
  font-size: 15px;
  font-weight: 500;
  color: #6c757d;
}

.Our_Products_Page .option_text:hover {
  color: #ff8c28;
}

.Our_Products_Page .filter-holder .Categories-holder {
  margin-bottom: 15%;
  padding: 0px 5px;
}

.Our_Products_Page .filter-holder .Categories-holder .heading-holder {
  margin-bottom: 15px;
}

.Our_Products_Page .filter-holder .title-border {
    border: 1px solid #ff8c28;
    width: 40px;
    margin: 0px auto 0px 0px;
}



.Our_Products_Page .filter-holder .Filter_btn {
    background: #ccc;
    color: #000;
    padding: 5px 20px;
    font-weight: 500;
    margin-left: 5px;
}

.Our_Products_Page .filter_price_text {
  display: flex;
  justify-content: space-between;
  padding: 0px 5px;
  margin-top: 5%;
}

.Our_Products_Page .filter_price_text .subPrice {
  text-decoration-line: none;
}

.Our_Products_Page .Popular_Product .Popular_Product_holder .img-holder .Popular_Product_img {
    width: 100%;
    height: 100px;
    cursor: pointer;
}

.Our_Products_Page .Popular_Product .Popular_Product_holder{
    margin-bottom: 15px;
}


.Our_Products_Page .rangebar_holder .range-slider {
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  display: block;
  position: relative;
  width: 100%;
  height: 5px;
  background: #ddd;
  border-radius: 4px;
}

.Our_Products_Page .rangebar_holder .range-slider .range-slider__thumb {
  width: 15px;
  height: 15px;
  background: #ff8c28;
}

.Our_Products_Page .rangebar_holder .range-slider .range-slider__range {
  background: #1d44ad;
}

.Our_Products_Page .tab-content {
  height: 900px;
  overflow: hidden;
  overflow-y: auto;
  padding: 0px 5px 0px 0px;
}

.Our_Products_Page .Our_Products_Page_Card .Banner_Card {
  position: relative;
}

.Our_Products_Page .option_text .form-check-input:checked {
  background-color: #1d44ad;
  border-color: #1d44ad;
}

.Our_Products_Page .option_text .form-check-input:focus {
 box-shadow: none;
}


.Our_Products_Page .tab-content::-webkit-scrollbar {
  width: 5px;
}
.Our_Products_Page .tab-content::-webkit-scrollbar:horizontal {
  height: 8px;
}
/* Track */
.Our_Products_Page .tab-content::-webkit-scrollbar-track {
  background: #E8E8E8;
}
.Our_Products_Page .tab-content::-webkit-scrollbar-track:horizontal {
  background: #E8E8E8;
}
/* Handle */
.Our_Products_Page .tab-content::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 5px;
}
.Our_Products_Page .tab-content::-webkit-scrollbar-thumb:horizontal{
   background: #c7c7c7;
  border-radius: 5px;
   }


@media (min-width: 0px) and (max-width: 767px) {
  .Popular_Product .Popular_Product_holder .img-holder .Popular_Product_img {
    height: 100px;
    object-fit: contain;
}

.product_info_holder {
  text-align: center;
  margin-top: 13px;
}

.Our_Products_Page .filter_price_text {
  display: block;
}

.Our_Products_Page .filter-holder .Filter_btn {
  margin-left: 0;
  margin-top: 8px;
}

.Our_Products_Page .filter-holder .heading {
  font-size: 12px;
}

.Our_Products_Page .Our_Products_Page_Card .Product_Swiper .Tab_Slider_Img {
  height: 220px;
}

.Our_Products_Page .rangebar_holder .range-slider .range-slider__thumb {
  width: 10px;
  height: 10px;
}

.Our_Products_Page .rangebar_holder .range-slider {
  height: 3px;
}
}

@media (min-width:768px) and (max-width: 991px) {
  .Popular_Product .Popular_Product_holder .img-holder .Popular_Product_img {
    height: 65px;
}

.Our_Products_Page .filter_price_text {
  display: block;
}

.Our_Products_Page .filter-holder .Filter_btn {
  margin-left: 0;
  margin-top: 8px;
}

.Our_Products_Page .Our_Products_Page_Card .Product_Swiper .Tab_Slider_Img {
  height: 200px;
}

.Our_Products_Page .rangebar_holder .range-slider .range-slider__thumb {
  width: 10px;
  height: 10px;
}

.Our_Products_Page .rangebar_holder .range-slider {
  height: 3px;
}

.Popular_Product .Popular_Product_holder .img-holder .Popular_Product_img {
  height: 100px;
  object-fit: contain;
}

.product_info_holder {
text-align: center;
margin-top: 13px;
}


}

@media (min-width:992px) and (max-width: 1023px) {
  .Popular_Product .Popular_Product_holder .img-holder .Popular_Product_img {
    height: 65px;
}

.Our_Products_Page .filter_price_text {
  display: block;
}

.Our_Products_Page .filter-holder .Filter_btn {
  margin-left: 0;
  margin-top: 8px;
}

.Our_Products_Page .Our_Products_Page_Card .Product_Swiper .Tab_Slider_Img {
  height: 200px;
}

.Our_Products_Page .rangebar_holder .range-slider .range-slider__thumb {
  width: 10px;
  height: 10px;
}

.Our_Products_Page .rangebar_holder .range-slider {
  height: 3px;
}

.Popular_Product .Popular_Product_holder .img-holder .Popular_Product_img {
  height: 100px;
  object-fit: contain;
}

.product_info_holder {
text-align: center;
margin-top: 13px;
}


}


@media (min-width:1024px) and (max-width: 1199px) {
  .Popular_Product .Popular_Product_holder .img-holder .Popular_Product_img {
    height: 65px;
}

.Our_Products_Page .filter_price_text {
  display: block;
}

.Our_Products_Page .filter-holder .Filter_btn {
  margin-left: 0;
  margin-top: 8px;
}

.Our_Products_Page .Our_Products_Page_Card .Product_Swiper .Tab_Slider_Img {
  height: 200px;
}

.Our_Products_Page .rangebar_holder .range-slider .range-slider__thumb {
  width: 10px;
  height: 10px;
}

.Our_Products_Page .rangebar_holder .range-slider {
  height: 3px;
}

.Popular_Product .Popular_Product_holder .img-holder .Popular_Product_img {
  height: 100px;
  object-fit: contain;
}

.product_info_holder {
text-align: center;
margin-top: 13px;
}


}