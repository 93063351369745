.WishList {
  margin: 5% 0%;
}

.WishList .WishList_Card_Holder .Banner_Card {
  position: relative;
  margin-bottom: 5%;
}
.WishList .add_all_btn {
  text-align: right;
}

@media (min-width: 0px) and (max-width: 991px) {
  .WishList .add_all_btn {
    text-align: center;
  }
}

@media (min-width: 0px) and (max-width: 485px) {
  .WishList .Pagenation .pagination .page-link {
    font-size: 15px;
    padding: 0px 12px;
  }
}
