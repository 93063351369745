.SubmitBtn {
    background: #212121 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: #000;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    padding: 8px 76px;
  }
  
  .heart-icon.active {
    color: #df4223;
  }
  
  .subscribe-btn {
    background-color: #df4223 !important;
    border-radius: 50px;
    color: #FFF;
    border: 0;
    font-size: 16px;
    padding: 7px 32px;
  }
  
  @media (min-width: 0px) and (max-width: 767px) {
    .subscribe-btn {
      font-size: 13px !important;
      padding: 7px 23px !important;
    }
  }
  