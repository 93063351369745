.Nav_Pills_Holder .Pills_Holder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Nav_Pills_Holder .Pills_Holder.nav-pills .nav-link.active, .Nav_Pills_Holder .Pills_Holder.nav-pills .show > .nav-link {
  background: none !important;
  font-weight: 600;
  font-size: 20px;
  color: #333333 !important;
  text-transform: uppercase;
  border-bottom: 4px solid #1d44ad;
  border-radius: 0px;
}

.Nav_Pills_Holder .Pills_Holder.nav-pills .nav-link {
  font-weight: 400;
  font-size: 20px;
  color: #333333;
  text-transform: uppercase;
}

@media (min-width: 0px) and (max-width: 320px) {
  .Nav_Pills_Holder .Pills_Holder.nav-pills .nav-link {
    font-size: 13px;
}

  .Nav_Pills_Holder .Pills_Holder.nav-pills .nav-link.active,
.Nav_Pills_Holder .Pills_Holder.nav-pills .show > .nav-link {
  font-size: 13px;
}
}



@media (min-width: 320px) and (max-width: 485px) {
  .Nav_Pills_Holder .Pills_Holder.nav-pills .nav-link {
    font-size: 15px;
  }

  .Nav_Pills_Holder .Pills_Holder.nav-pills .nav-link.active,
.Nav_Pills_Holder .Pills_Holder.nav-pills .show > .nav-link {
  font-size: 15px;
}


}


@media (min-width: 485px) and (max-width: 991px) {
  .Nav_Pills_Holder .Pills_Holder.nav-pills .nav-link {
    font-size: 15px;
  }

  .Nav_Pills_Holder .Pills_Holder.nav-pills .nav-link.active,
.Nav_Pills_Holder .Pills_Holder.nav-pills .show > .nav-link {
  font-size: 15px;
}


}