@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700;800;900&display=swap');

body {
    font-family: "Roboto", "sans-serif" !important;
}


p {
    margin-bottom: 0px !important;
}

a {
    text-decoration: none !important;
}


.form-select:focus {
    box-shadow: none !important;
}

.breadcrumb-item a {
    font-size: 12px;
    color: #808080;
    text-decoration: none;
}


