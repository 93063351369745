.LogIn {
  padding: 30px 0px;
}

.LogIn .bg-color .border-bottom-color {
  border-bottom: 5px solid;
  border-image: linear-gradient(to right, #ff8c28 0%, #1d44ad 100%, #fff0 0%);
  border-image-slice: 1;
  padding: 0px 0px 30px 0px;
}

.LogIn .bg-color {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.LogIn .loginlogo-div {
  text-align: center;
  margin-bottom: 20px;
}

.LogIn .loginlogo-div .loginlogo {
  width: 157px;
  height: 61px;
  text-align: center;
}

/*LogIn form css start*/
.LogIn .bg-color .back_to_span p {
  font-size: 16px;
  font-weight: 500;
  color: #ff8c28;
  text-align: center;
}

.LogIn .bg-color .heading-holder {
  margin-top: 20%;
}

.LogIn .bg-color .heading-holder h4 {
  font-size: 25px;
  font-weight: 600;
  color: #2b2b2b;
  text-align: center;
}

.LogIn .bg-color .heading-holder p {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #323232;
}

.LogIn .bg-color .log_in_form {
  margin-top: 5%;
}

.LogIn .bg-color .log_in_form .form-control {
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  font-size: 14px;
  color: #a2a2a2;
  height: 40px;
}

.LogIn .bg-color .log_in_form .form-control::placeholder {
  font-size: 14px;
  color: #a2a2a2;
}

.LogIn .bg-color .log_in_form .form-control:focus {
  box-shadow: none;
}

.LogIn .bg-color .log_in_form .Forgot_password {
  font-size: 16px;
  font-weight: 500;
  color: #323232;
  display: flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
}

.LogIn .bg-color .log_in_form .btn-holder {
  text-align: center;
  margin-top: 10%;
}

.LogIn .bg-color .log_in_form .create_account {
  margin-top: 3%;
}

.LogIn .bg-color .log_in_form .create_account p {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #323232;
  cursor: pointer;
}

.LogIn .bg-color .log_in_form .create_account .cancel-text {
  text-align: center;
  text-decoration: underline;
  font-size: 15px;
  display: flex;
  font-weight: 400;
  color: #323232;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.LogIn .bg-color .log_in_form .create_account .login_text p {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #323232;
}

.LogIn .bg-color .log_in_form .create_account .login_text span {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #1D44AD;
  cursor: pointer;
  margin-left: 5px;
}

.LogIn .bg-color .log_in_form .log_in_with .log_in_with {
  margin-top: 10%;
}

.LogIn .bg-color .log_in_form .log_in_with p {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #979797;
  display: flex;
}

.LogIn .bg-color .log_in_form .log_in_with p:before,
.LogIn .bg-color .log_in_form .log_in_with p:after {
  color: white;
  content: "";
  flex: 1;
  border-bottom: 1px solid #979797;
  margin: auto 5px;
}

.LogIn .bg-color .log_in_form .social_img_holder {
  /* text-align: center; */
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 50%;
  height: 38px;
  margin-left: auto;
  margin-right: auto;
}

.LogIn .bg-color .log_in_form .social_img_holder .social_img {
  height: 30px;
}

.LogIn .bg-color .log_in_form .password-field-holder {
  position: relative;
}

.LogIn .bg-color .log_in_form .eye-icon-holder {
  position: absolute;
  top: 0;
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.LogIn .bg-color .log_in_form .eye-icon {
  font-size: 15px;
  color: #6c757d;
}

/*LogIn form css start*/
@media (min-width: 280px) and (max-width: 320px) {
  .LogIn .bg-color .back_to_span p {
    margin-top: 5%;
  }

  .LogIn .bg-color .heading-holder h4 {
    font-size: 20px;
  }

  .LogIn .bg-color .heading-holder {
    margin-top: 5%;
  }

  .LogIn .bg-color .log_in_form .social_img_holder {
    margin-bottom: 5%;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .LogIn .bg-color .back_to_span p {
    margin-top: 5%;
  }

  .LogIn .bg-color .heading-holder h4 {
    font-size: 20px;
  }

  .LogIn .bg-color .heading-holder {
    margin-top: 5%;
  }

  .LogIn .bg-color .log_in_form .social_img_holder {
    margin-bottom: 5%;
  }
}

@media (min-width: 486px) and (max-width: 576px) {
  .LogIn .bg-color .back_to_span p {
    margin-top: 5%;
  }

  .LogIn .bg-color .heading-holder h4 {
    font-size: 20px;
  }

  .LogIn .bg-color .heading-holder {
    margin-top: 5%;
  }

  .LogIn .bg-color .log_in_form .social_img_holder {
    margin-bottom: 5%;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .LogIn .bg-color .back_to_span p {
    margin-top: 5%;
  }

  .LogIn .bg-color .heading-holder h4 {
    font-size: 20px;
  }

  .LogIn .bg-color .heading-holder {
    margin-top: 5%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .LogIn .bg-color .back_to_span p {
    margin-top: 5%;
  }

  .LogIn .bg-color .heading-holder h4 {
    font-size: 20px;
  }
}
