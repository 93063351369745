.blog {
    margin-top: 3%;
    margin-bottom: 5%;
}

.blog .card {
    border: none;
    background: #FFF;
    box-shadow: 0px 0px 17px #0000001C;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 24px 10px 16px 11px;
}

.blog .card .read-more-align .readmore-p .readmorefafa {
    font-size: 9px;
    margin-left: 5px;
}


.blog .recent-post-div .blog-card-holder {
    height: 400px;
    overflow: hidden;
    overflow-y: scroll;
}

.blog .recent-post-div .blog-card-holder::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.blog .recent-post-div .blog-card-holder::-webkit-scrollbar-track {
    background: #E8E8E8;
}

/* Handle */
.blog .recent-post-div .blog-card-holder::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 5px;
}

.blog .card .image-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.blog .card .image-holder .blog-img {
    border-radius: 5px;
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.blog .card .text-holder p {
    font-size: 12px;
    color: #191919;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    margin-bottom: 2%;
}

.blog .card .text-holder h3 {
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.blog .card .read-more-align .readmore-p {
    font-size: 12px;
    color: #ff8c28;
    margin-bottom: 0px;
    display: flex;
    justify-content: end;
    align-items: center;
}

.blog .card .read-more-align {
    text-align: right;
}

.blog .recent-post-div {
    background: #F6F6F6 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 25px;
}

.blog .recent-post-div .title {
    font-size: 16px;
    font-weight: 700;
}

.blog .recent-post-div .recent-post-img {
    width: 60px;
    height: 60px;
    border-radius: 5px;
}

.blog .post-title {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.blog .post-date {
    color: #000;
    font-size: 12px;
}

.blog a {
    color: #000;
}

.blog .card .card-body {
    padding: 0px 20px 0px 0px;
}

@media(min-width:0px) and (max-width:767px) {
    .blog .card .text-holder h3 {
        font-size: 14px;
    }

    .blog .card {
        padding: 13px 10px 16px 11px;
    }

    .blog .card .text-holder {
        margin-top: 5px;
    }
}

@media(min-width:0px) and (max-width:420px) {
   
}

@media(min-width:421px) and (max-width:577px) {
   
}

@media(min-width:578px) and (max-width:767px) {
   
}

@media(min-width:768px) and (max-width:992px) {
  
}

@media(min-width:992px) and (max-width:1200px) {
  
}