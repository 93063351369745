.Pagenation {
  margin: 5% 0%;
}
.Pagenation .pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Pagenation .pagination .page-link {
  border: none;
  color: #000;
  padding: 0px 10px;
}

.Pagenation .pagination .page-link:focus {
  box-shadow: none;
}

.Pagenation .pagination .page-item.active > .page-link,
.page-link.active {
  color: #ff8c28;
  background: none;
  font-weight: 500;
  border-bottom: 2px solid #1d44ad;
}

@media (min-width: 0px) and (max-width: 320px) {
  .Pagenation .pagination .page-link {
    font-size: 12px;
    padding: 0px 4px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Pagenation .pagination .page-link {
    font-size: 12px;
    padding: 0px 6px;
  }
}
