.Style_On {
  margin-top: 0px;
  margin-bottom: 5%;
}

.Style_On .Style_On_Drop_Holder {
  background: #f6f9ff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 45px 3px;
}

.Style_On .Style_On_Drop_Holder .style-btn-holder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Style_On .Style_On_Drop_Holder .style-btn {
  border: 1px solid #1d44ad;
  background: #fff;
  border-radius: 3px;
  padding: 5px 40px;
  font-size: 15px;
  font-weight: 400;
  color: #333333;
  position: relative;
  z-index: 0;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition-duration: 0.5s;
}

.Style_On .Style_On_Drop_Holder .style-btn:before,
.Style_On .Style_On_Drop_Holder .style-btn:after {
  content: " ";
  position: absolute;
  top: 0;
  z-index: -1;
  left: -100%;
  width: 100%;
  height: 100%;
  border: 1px solid #1d44ad;
  background-color: #1d44ad;
  transition-duration: 0.5s;
}

.Style_On .Style_On_Drop_Holder .style-btn:before {
  width: 0;
  height: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition-duration: 0.3s;
}

.Style_On .Style_On_Drop_Holder .style-btn:hover:before {
  width: 100%;
  height: 100%;
  color: #fff;
}

.Style_On .Style_On_Drop_Holder .style-btn:hover {
  color: #fff;
}

@media (min-width: 0px) and (max-width: 320px) {
  .Style_On .Style_On_Drop_Holder .style-btn {
    font-size: 12px;
  }
}


@media (min-width: 321px) and (max-width: 485px) {
  .Style_On .Style_On_Drop_Holder .style-btn {
    font-size: 12px;
  }
}