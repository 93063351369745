.footer-section {
  background: #f6f6f6 0% 0% no-repeat padding-box;
}

.footer-section .footermains {
  border-bottom: 1px solid #000;
}

.footer-section .toptext {
  display: flex;
  align-items: center;
  height: 75px;
}

.footer-section .toptext .follows {
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #0e0e0e;
  margin-bottom: 0;
  opacity: 1;
}

.footer-section .toptext .follow_link {
  color: #1d44ad;
}

.footer-section .toptext .footer-logos {
  width: 195px;
  height: 75px;
}

.footer-section .toptext .footericnss {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin-left: 10px;
  width: 30px;
  height: 30px;
  background: #ff8c28 0% 0% no-repeat padding-box;
  color: #fff;
  font-size: 13px;
  opacity: 1;
}

.footer-section .linktxt {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  padding-bottom: 10px;
}
.footer-section .addresdetails {
  display: flex;
  margin-bottom: 11px;
}
.footer-section .addresicns {
  font-size: 16px;
  margin-right: 10px;
}
.footer-section .bottomtext {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
}

.footer-section .checkss {
  color: green;
  margin-right: 5px;
}
.footer-section .copyright {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  margin-bottom: 0;
}
.footer-section .coprightspan {
  font-weight: 600;
}

.footer-section a {
  text-decoration: none !important;
  color: #000;
}
@media (min-width: 0px) and (max-width: 767px) {
  .footer-section .toptext .footer-logos {
    width: 130px;
    height: 50px;
  }

  .footer-section .toptext {
    height: 55px;
  }
  .footer-section .toptext .follows {
    font-size: 12px;
  }
  .footer-section .toptext .footericnss {
    margin-left: 7px;
    width: 25px;
    height: 26px;
    font-size: 10px;
  }

  .footer-section .linktxt {
    font-size: 11px;
    margin-bottom: 8px;
  }
  .footer-section .copyright {
    font-size: 9px;
    margin-right: 2px;
  }


}

@media (min-width: 767px) and (max-width: 991px) {
  .footer-section .toptext .footer-logos {
    width: 130px;
    height: 50px;
  }

  .footer-section .toptext {
    height: 55px;
  }
  .footer-section .toptext .follows {
    font-size: 19px;
  }
  .footer-section .toptext .footericnss {
    margin-left: 7px;
    width: 25px;
    height: 26px;
    font-size: 11px;
  }

  .footer-section .linktxt {
    font-size: 11px;
    margin-bottom: 8px;
  }
  .footer-section .copyright {
    font-size: 9px;
    margin-right: 2px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .footer-section .toptext .footer-logos {
    width: 180px;
    height: 65px;
  }
  .footer-section .toptext {
    height: 70px;
  }
  .footer-section .toptext .follows {
    font-size: 20px;
  }
  .footer-section .linktxt {
    font-size: 13px;
  }
  .footer-section .copyright {
    font-size: 11px;
  }
  .footer-section .bottomtext {
    padding: 15px 0px;
  }
}
