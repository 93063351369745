.New_Arrivels .Shop_Trends_Cards .Women_Tab_Content_img {
  width: 100%;
  height: 470px;
  object-fit: contain;
  border-radius: 10px;
}

@media (min-width: 0px) and (max-width: 320px) {
  .New_Arrivels .Shop_Trends_Cards .Women_Tab_Content_img {
    object-fit: unset;
    height: 250px;
}

  .New_Arrivels .main_img_holder .Banner_Card .img-holder {
    margin-bottom: 15px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .New_Arrivels .Shop_Trends_Cards .Women_Tab_Content_img {
    object-fit: unset;
    height: 400px;
  }

  .New_Arrivels .main_img_holder .Banner_Card .img-holder {
    margin-bottom: 15px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .New_Arrivels .Shop_Trends_Cards .Women_Tab_Content_img {
    object-fit: unset;
    height: 500px;
  }

  .New_Arrivels .main_img_holder .Banner_Card .img-holder {
    margin-bottom: 15px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .New_Arrivels .Shop_Trends_Cards .Women_Tab_Content_img {
    object-fit: unset;
    height: 500px;
  }

  .New_Arrivels .main_img_holder .Banner_Card .img-holder {
    margin-bottom: 15px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .New_Arrivels .Shop_Trends_Cards .Women_Tab_Content_img {
    object-fit: unset;
    height: 350px;
}
}

@media (min-width: 991px) and (max-width:1023px) {
  .New_Arrivels .Shop_Trends_Cards .Women_Tab_Content_img {
    object-fit: unset;
    height: 350px;
}
}


@media (min-width: 1024px) and (max-width:1199px) {
  .New_Arrivels .Shop_Trends_Cards .Women_Tab_Content_img {
    object-fit: unset;
    height: 350px;
}
}

@media (min-width: 1200px) and (max-width:1399px) {
  .New_Arrivels .Shop_Trends_Cards .Women_Tab_Content_img {
    height: 400px;
    object-fit: unset;
}
}