.Category {
  margin-bottom: 5%;
}

.Category .imgHovermain .swiperImg {
  width: 100%;
  height: 214px;
  transition: 0.3s;
  border-radius: 5px;
}


.Category .imgHovermain {
  overflow: hidden;
  border-radius: 5px;
}

/* .Category .shopCategoryhead h2 {
  font-size: 30px;
  font-weight: bold;
} */

.Category .mySwiper .cardDEtails h3 {
  font-size: 18px;
  color: #333333;
  text-transform: capitalize;
  margin-top: 15px;
  cursor: pointer;
}

.Category .mySwiper .cardDEtails p {
  font-size: 12px;
  cursor: pointer;
}

.Category .nav-pills .nav-link {
  background: transparent;
  color: #333333;
  /* padding: 4px; */
  padding-bottom: 0px;
  padding-left: 0px;
}

.Category .nav-pills .nav-link.active {
  font-weight: 600;
  background-color: transparent;
  color: #333333;
}

.Category .imgHovermain .swiperImg:hover {
  transform: scale(1.1);
}

/* 
.Category {
    position: relative;
} */

.Category .catogryswiperarrow {
  width: 100%;
  display: flex;
  justify-content: end;
}

.Category .product-detail .swiperCategory {
  position: relative;
  padding-bottom: 45px;
}

.Category
  .product-detail
  .swiperCategory
  .swiper-button-prev.swiper-button-disabled,
.Category .product-detail .swiperCategory .swiper-button-prev {
  content: url("../../../../public//assets/Home/Select_By_Catagory/Men/arrow_right.png");
  z-index: 2;
  cursor: pointer;
  object-fit: contain;
  position: absolute;
  top: 93%;
  left: 92%;
}
.Category
  .product-detail
  .swiperCategory
  .swiper-button-next.swiper-button-disabled,
.Category .product-detail .swiperCategory .swiper-button-next {
  content: url("../../../../public//assets/Home/Select_By_Catagory/Men/arrow_left.png");
  z-index: 2;
  cursor: pointer;
  object-fit: contain;
  position: absolute;
  top: 93%;
}

.Category .product-detail {
  position: relative;
}

.categoryTabs {
  display: flex;
  flex-direction: column;
}


.Category .product-detail .swiperCategory .swiper-pagination {
  text-align: left;
  width: fit-content;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .categoryTabs {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-bottom: 14px;
  }

  .Category .main-slider .arrow-next {
    bottom: 7%;
    right: 5%;
    width: 25px;
  }

  .Category .imgHovermain .swiperImg {
    height: 180px;
  }

  .Category .imgHovermain .swiperImg {
    height: 150px;
  }
  .Category .main-slider .arrow-back {
    bottom: 7%;
    width: 25px;
    right: 15%;
  }
  .Category .shopCategoryhead h2 {
    /* font-size: 23px; */
    text-align: center;
  }

  .Category
    .product-detail
    .swiperCategory
    .swiper-button-prev.swiper-button-disabled,
  .Category .product-detail .swiperCategory .swiper-button-prev {
    left: 79%;
  }
}

@media screen and (min-width: 769px) and (max-width: 991px) {
  .Category
    .product-detail
    .swiperCategory
    .swiper-button-prev.swiper-button-disabled,
  .Category .product-detail .swiperCategory .swiper-button-prev {
    left: 85%;
  }
  .Category .imgHovermain .swiperImg {
    height: 180px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1023px) {
  .Category
    .product-detail
    .swiperCategory
    .swiper-button-prev.swiper-button-disabled,
  .Category .product-detail .swiperCategory .swiper-button-prev {
    left: 90%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .Category
    .product-detail
    .swiperCategory
    .swiper-button-prev.swiper-button-disabled,
  .Category .product-detail .swiperCategory .swiper-button-prev {
    left: 90%;
  }
}
