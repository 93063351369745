.Crazy_Deals {
    background: #EEEEEE 0% 0% no-repeat padding-box;
    padding: 20px 0px 40px 0px;
}

.Crazy_Deals .Crazy_Deals_Swiper_holder {
    margin-top: 50px;
}

.Crazy_Deals .Crazy_Deals_Swiper_holder .Crazy_Deals_Swiper_Img_holder  .Crazy_Deals_Swiper_Img {
    width: 100%;
   height: 349px;
    /* object-fit: contain; */
    border-radius: 10px;
    transition: 0.3s;
    overflow: hidden;
    
}
.Crazy_Deals .Crazy_Deals_Swiper_holder .Crazy_Deals_Swiper_Img_holder  .Crazy_Deals_Swiper_Img:hover {
    transform: scale(1.1);
}

.CrazyDealsSwiper {
    padding-bottom: 40px;
}

.Crazy_Deals .Crazy_Deals_Swiper_holder  .crazybanmain{
    overflow: hidden;
    border-radius: 13px;
    height: 349px;
}
@media (min-width:0px) and (max-width:320px){
    .Crazy_Deals .Crazy_Deals_Swiper_holder  .crazybanmain {
        height: 120px;
    }
    .Crazy_Deals .Crazy_Deals_Swiper_holder .Crazy_Deals_Swiper_Img_holder  .Crazy_Deals_Swiper_Img {
        height: 120px;
    }

    .Crazy_Deals {
        padding: 1px 0px 0px 0px;
    }

    .Crazy_Deals .Crazy_Deals_Swiper_holder {
        margin-top: 0px;
    }
}
@media (min-width:321px) and (max-width:485px){
    .Crazy_Deals .Crazy_Deals_Swiper_holder  .crazybanmain {
        height: 150px;
    }
    .Crazy_Deals .Crazy_Deals_Swiper_holder .Crazy_Deals_Swiper_Img_holder  .Crazy_Deals_Swiper_Img {
        height: 150px;
    }

    .Crazy_Deals {
        padding: 1px 0px 0px 0px;
    }

    .Crazy_Deals .Crazy_Deals_Swiper_holder {
        margin-top: 0px;
    }
}

@media (min-width:486px) and (max-width:767px){
    .Crazy_Deals .Crazy_Deals_Swiper_holder  .crazybanmain{
        height: 190px;
    }
    .Crazy_Deals .Crazy_Deals_Swiper_holder .Crazy_Deals_Swiper_Img_holder  .Crazy_Deals_Swiper_Img {
        height: 190px;
    }
}
@media (min-width:768px) and (max-width:991px){
    .Crazy_Deals .Crazy_Deals_Swiper_holder  .crazybanmain{
        height: 250px;
    }
    .Crazy_Deals .Crazy_Deals_Swiper_holder .Crazy_Deals_Swiper_Img_holder  .Crazy_Deals_Swiper_Img {
        height: 250px;
    }
}
@media (min-width:992px) and (max-width:1200px){
    .Crazy_Deals .Crazy_Deals_Swiper_holder  .crazybanmain{
        height: 300px;
    }
    .Crazy_Deals .Crazy_Deals_Swiper_holder .Crazy_Deals_Swiper_Img_holder  .Crazy_Deals_Swiper_Img {
        height: 300px;
    }
}