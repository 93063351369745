.why-choose {
  background: #fff6f6 0% 0% no-repeat padding-box;
  padding: 40px 0px 80px 0px;
}

.why-choose .choose-fafa-bg {
  background-color: #fff;
  border-radius: 50px;
  width: 120px;
  height: 60px;
  box-shadow: 0px 0px 20px #00000029;
}

.why-choose .choose-fafa-icons {
  width: 31px;
  height: 31px;
  margin-top: 15px;
}

.why-choose .choos-col .title {
  font-size: 18px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.why-choose .choos-col .sub-text {
  font-size: 14px;
  margin-bottom: 0px;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.why-choose .bd-right {
  padding-bottom: 40px;
}

.why-choose .choos-col {
  border-right: 1px solid #000;
}

.why-choose .bd-right:nth-child(3) .choos-col {
  border-right: none;
}

@media (min-width: 0px) and (max-width: 767px) {
  .why-choose .choos-col {
    border-right: none;
  }

  .why-choose .choos-col .title {
    font-size: 15px;
  }

  .why-choose .choos-col .sub-text {
    font-size: 13px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .why-choose .choose-fafa-bg {
    width: 120px;
    height: 44px;
  }

  .why-choose .choose-fafa-icons {
    width: 29px;
    height: 29px;
    margin-top: 7px;
  }

  .why-choose .choos-col .title {
    font-size: 16px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .why-choose .choose-fafa-bg {
    width: 50px;
    height: 47px;
  }

  .why-choose .choose-fafa-icons {
    width: 29px;
    height: 29px;
    margin-top: 10px;
  }

  .why-choose .choos-col {
    border-right: none;
  }
}

@media (min-width: 486px) and (max-width: 767px) {
  .why-choose .choose-fafa-bg {
    width: 70px;
    height: 47px;
  }

  .why-choose .choose-fafa-icons {
    width: 29px;
    height: 29px;
    margin-top: 10px;
  }
}

@media (min-width: 360px) and (max-width: 486px) {
  .why-choose .choose-fafa-bg {
    width: 94px;
    height: 47px;
  }

  .why-choose .choose-fafa-icons {
    width: 29px;
    height: 29px;
    margin-top: 10px;
  }
}

@media (min-width: 0px) and (max-width: 359px) {
  .why-choose .choose-fafa-bg {
    width: 124px;
    height: 47px;
  }

  .why-choose .choose-fafa-icons {
    width: 29px;
    height: 29px;
    margin-top: 9px;
  }
}
