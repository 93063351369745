.Select_Drop {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.Select_Drop .option_select .form-select {
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 2px solid #00000029;
  border-radius: 0px;
  font-size: 15px;
  font-weight: 800;
  color: #333333;
  width: 180px;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 320 512%27%3E%3Cpath fill=%27%23131313%27 d=%27M310.6 160.9L180.6 288c-4.7 4.7-12.3 4.7-17 0L9.4 160.9C3.1 155.2 0 147.6 0 139.8c0-17.8 13.8-32 32-32h256c17.7 0 32 14.3 32 32 0 7.8-3.1 15.4-9.4 21.1z%27%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select_options .react-select__option{
  position: absolute;
  z-index: 999999;
  background: #ccc;
}

@media (min-width: 0px) and (max-width: 320px) {
  .Select_Drop {
    justify-content: space-between;
    padding: 0px 3px;
    display: block;
    margin-bottom: 20px;
}

.Select_Drop .option_select {
  margin-top: 10px;
}


.Select_Drop .option_select .form-select {
  font-size: 12px;
  width: 100%;
}
}

@media (min-width: 321px) and (max-width: 485px) {
  .Select_Drop {
    justify-content: space-between;
    padding: 0px 3px;
    display: block;
    margin-bottom: 20px;
}

.Select_Drop .option_select {
  margin-top: 10px;
}


.Select_Drop .option_select .form-select {
  font-size: 12px;
  width: 100%;
}
}

@media (min-width: 486px) and (max-width: 767px) {
  .Select_Drop {
    justify-content: space-between;
    padding: 0px 30px;
    margin-bottom: 20px;
}


}

@media (min-width: 768px) and (max-width: 991px) {
  .Select_Drop {
    justify-content: space-between;
    padding: 0px 10px;
    margin-bottom: 10px;
  }

  .Select_Drop .option_select .form-select {
    font-size: 14px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .Select_Drop .option_select .form-select {
    width: 145px;
  }
}
