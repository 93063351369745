.ExclusiveCollection .nav-pills .nav-link.active {
    background: #1D44AD 0% 0% no-repeat padding-box;
    border-radius: 5px;
    text-align: center;
}

.ExclusiveCollection .nav-pills .nav-link {
    color: #000;
    width: 150px;
    text-align: center;
    background: #F6F6F6 0% 0% no-repeat padding-box;
    border-radius: 5px;
}

.ExclusiveCollection .ExclusiveTabs {
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
}

.ExclusiveCollection .exclusiveImgmain {
    position: relative;
    margin-top: 10px;
    transition: 0.3s;
}
.ExclusiveCollection .exclusiveImgmain:hover {
    margin-top: 0px;
    transition: 0.3s;
}

.ExclusiveCollection .exclusiveImgmain .exclusivecardImg {
    width: 100%;
    height: 175px;
}

.ExclusiveCollection .exclusiveImgmain p {
    color: #fff;
    text-align: center;
}

.ExclusiveCollection .exclusiveCollectswiper{
   padding-bottom: 50px;
}

.ExclusiveCollection .exclusiveImgmain .onImagetxtsec {
    background: #2A2A2A 0% 0% no-repeat padding-box;
    /* mix-blend-mode: multiply; */
    position: absolute;
    bottom: 11px;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
    opacity: 0.8;
}