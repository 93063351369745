.Shop_Trends {
  margin-bottom: 5%;
}

.Shop_Trends .Shop_Trends_Cards .Women_Tab_Content_img {
  width: 100%;
  height: 496px;
  object-fit: contain;
  border-radius: 10px;
}

.Shop_Trends .Shop_Trends_Cards .tab_content_holder {
  margin-top: 3%;
}

.Shop_Trends
  .Shop_Trends_Cards
  .tab_content_holder
  .Men_Tab_Content_img_holder {
  position: relative;
}

.Shop_Trends .Shop_Trends_Cards .tab_content_holder .shoptrendimg {
  /* width: 100%;
  height: 496px; */
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  border-radius: 10px;
}

.Shop_Trends
  .Shop_Trends_Cards
  .tab_content_holder
  .shoptrendimg:hover
  .shoptrenss {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}

@media (min-width: 0px) and (max-width: 320px) {
  .Shop_Trends .Shop_Trends_Cards .Women_Tab_Content_img {
    height: 120px;
    object-fit: unset;
}


  .Shop_Trends .Shop_Trends_Cards .tab_content_holder .shoptrendimg {
    margin-bottom: 5%;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Shop_Trends .Shop_Trends_Cards .Women_Tab_Content_img {
    height: 200px;
    object-fit: unset;
}

  .Shop_Trends .Shop_Trends_Cards .tab_content_holder .shoptrendimg {
    margin-bottom: 5%;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Shop_Trends .Shop_Trends_Cards .Women_Tab_Content_img {
    height: 250px;
    object-fit: unset;
  }

  .Shop_Trends .Shop_Trends_Cards .tab_content_holder .shoptrendimg {
    margin-bottom: 5%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .Shop_Trends .Shop_Trends_Cards .Women_Tab_Content_img {
    height: 250px;
    object-fit: unset;
  }

  .Shop_Trends .Shop_Trends_Cards .tab_content_holder .shoptrendimg {
    margin-bottom: 5%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Shop_Trends .Shop_Trends_Cards .Women_Tab_Content_img {
    height: 300px;
    object-fit: unset;
  }
}


@media (min-width: 992px) and (max-width: 1023px) {
  .Shop_Trends .Shop_Trends_Cards .Women_Tab_Content_img {
    height: 400px;
    object-fit: unset;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .Shop_Trends .Shop_Trends_Cards .Women_Tab_Content_img {
    height: 400px;
    object-fit: unset;
  }
}

/* @media (min-width:0px) and (max-width:320px){
  .shoptrendimg {
    height: 250px;
    margin-bottom: 15px;
}
  .Shop_Trends .Shop_Trends_Cards .Women_Tab_Content_img {
    height: 250px;
}
}
@media (min-width:321px) and (max-width:485px){
  .shoptrendimg {
    height: 320px;
    margin-bottom: 15px;
}
  .Shop_Trends .Shop_Trends_Cards .Women_Tab_Content_img {
    height: 320px;
}
} */

@media (min-width: 1200px) and (max-width: 1399px) {
  .Shop_Trends .Shop_Trends_Cards .Women_Tab_Content_img {
    height: 400px;
    object-fit: unset;
  }
}

