.Explore_Brands {
  margin-bottom: 3%;
}

.Explore_Brands .Explore_Brands_Slider_Holder .Explore_Brands_Slider {
  height: 300px;
  padding: 30px 0px;
}

.Explore_Brands .Explore_Brands_Slider_Holder .Explore_Brands_Slider .Explore_Brands_Slider_Img_holder{
    text-align: center;
}

.Explore_Brands .Explore_Brands_Slider_Holder .Explore_Brands_Slider .Explore_Brands_Slider_Img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}


@media (min-width: 0px) and (max-width: 320px) {
  .Explore_Brands .Explore_Brands_Slider_Holder .Explore_Brands_Slider {
    height: 140px;
    padding-bottom: 15px;
}

.Explore_Brands .Explore_Brands_Slider_Holder .Explore_Brands_Slider .Explore_Brands_Slider_Img {
  height: 45px;
}
}

@media (min-width: 320px) and (max-width: 485px) {
  .Explore_Brands .Explore_Brands_Slider_Holder .Explore_Brands_Slider {
    height: 140px;
    padding-bottom: 15px;
}

.Explore_Brands .Explore_Brands_Slider_Holder .Explore_Brands_Slider .Explore_Brands_Slider_Img {
  height: 45px;
}
  }